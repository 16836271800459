import React, { useEffect } from "react"

import "../../assets/sass/main.scss"
import "typeface-roboto"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import { connect } from "react-redux"
import { setCorrespondingLangSlug, setToLanguage } from "../../state/actions"
import Section from "../../components/section"
import SectionInner from "../../components/section-inner"
import "./single-post.scss"
import GatsbyImage from "gatsby-image"
import IconLink from "../../components/icon-link"
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore"
import { useTranslation } from "react-i18next"
import contentParser from "gatsby-wpgraphql-inline-images"


const SinglePost = (
  {
    setCorrespondingLangSlug,
    setToLanguage,
    pageContext: {
      pluginOptions,
      post
    },
    correspondingLangSlug,
    lang,
  },
) => {
  const { i18n, t } = useTranslation()

  const correspondingTranslation = post.translations.find((translation) => {
    return translation.language.locale !== post.language.locale
  })

  useEffect(() => {
    if (post.language.locale !== lang) {
      setToLanguage(post.language.locale)
      i18n.changeLanguage(post.language.locale)
    }

    if (correspondingTranslation && correspondingTranslation.fullSlug !== correspondingLangSlug) {
      setCorrespondingLangSlug(correspondingTranslation.fullSlug)
    }
  })


  return (
    <Layout>
      <SEO title={post.title} lang={post.language.locale}/>


      <Section className="SinglePost">

        <SectionInner className="SinglePost-inner Section--bPadding u-textMaxWidth u-applyListStyle">
          {
            post.featuredImage &&
            (
              (post.featuredImage.imageFile && post.featuredImage.imageFile.extension !== "svg") ?
                <GatsbyImage className="SinglePost-cover" fluid={post.featuredImage.imageFile.childImageSharp.fluid}
                             alt={post.featuredImage.altText}/>
                :
                <img className="SinglePost-cover"
                     src={post.featuredImage.imageFile ? post.featuredImage.imageFile.publicURL : post.featuredImage.sourceUrl}
                     alt={post.featuredImage.altText}/>
            )
          }
          <IconLink className="SinglePost-backLink" to={post.language.locale === "de_DE" ? "/" : "/en/"}
                    icon={NavigateBeforeIcon} before>{t("Zurück zur Projekt Übersicht")}</IconLink>

          <h1 className="SinglePost-title t-h1" dangerouslySetInnerHTML={{__html: post.title}} />
          {/*<div className="SinglePost-body" dangerouslySetInnerHTML={{__html: post.content}} />*/}

          <div className="SinglePost-body">
            {contentParser({ content: post.content }, pluginOptions)}
          </div>

        </SectionInner>

      </Section>

    </Layout>
  )
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    correspondingLangSlug: state.correspondingLangSlug,
  }
}

const mapDispatchToProps = {
  setCorrespondingLangSlug,
  setToLanguage,
}


export default connect(mapStateToProps, mapDispatchToProps)(SinglePost)
